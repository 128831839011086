import { isTouchDevice } from '../../util/touchDevice';

$(document).on('turbolinks:load', function () {
  var $mainMenuDropdownBtn = $('.js-header-main-menu-dropdown-btn');
  var $mainMenuToggle = $mainMenuDropdownBtn.find('.js-main-menu-toggle')
  var $offCanvasElement = $('.offcanvas');
  var hideDropdownTimeout;

  function setupMainMenuDropdownMouseEvents() {
    $mainMenuDropdownBtn.on('mouseover', function(){
      clearTimeout(hideDropdownTimeout);
      $mainMenuToggle.dropdown('show')
    });
    $mainMenuDropdownBtn.on('mouseleave', function(e){
      hideDropdownTimeout = setTimeout(function(){
        var relatedTarget = e.relatedTarget;

        if (!relatedTarget || (!$(relatedTarget).closest($mainMenuToggle).length)) {
          $mainMenuToggle.dropdown('hide');
        }
      }, 150);
    })
  }

  function removeMainMenuHoverEvents() {
    $mainMenuDropdownBtn.off('mouseover mouseleave');
  }
  
  function updatePageResponsiveness() {
    var smallPageWidth = 768;

    if ($(window).width() > smallPageWidth && !isTouchDevice()) {
      closeCollapsableDrawer()
      removeMainMenuHoverEvents();  
      setupMainMenuDropdownMouseEvents()
    } else {
      openCollapsableDrawer()
      removeMainMenuHoverEvents();  
    }
  }

  function getOffCanvasBackdrop() {
    var $offCanvasBackdrop = $('.offcanvas-backdrop');
    
    // If it doesn't exist, create it and return the newly created element
    if ($offCanvasElement.hasClass('show') && $offCanvasBackdrop.length === 0) {
      $('body').append('<div class="offcanvas-backdrop fade"></div>');
      $offCanvasBackdrop = $('.offcanvas-backdrop');
    }
    return $offCanvasBackdrop;
  }
  
  function openCollapsableDrawer() {
    // Switch to offcanvas
    $('.js-dropdown-main-menu').removeClass('show')
    $offCanvasElement.show() 
    getOffCanvasBackdrop().addClass('show')
    $mainMenuToggle.attr('data-bs-toggle', 'offcanvas');
    $mainMenuToggle.attr('data-bs-target', '#explore-marketplace-offcanvas');
  }

  function closeCollapsableDrawer() {
    // Switch back to dropdown
    $offCanvasElement.hide()
    getOffCanvasBackdrop().removeClass("show");
    $mainMenuToggle.attr('data-bs-toggle', 'dropdown');
    $mainMenuToggle.removeAttr('data-bs-target');
  }

  function checkCanvasElement() {
    if(!$offCanvasElement.hasClass('show')){
      $(".offcanvas-backdrop").remove();
    }
  }
  
  $(window).on('resize', function() {
    updatePageResponsiveness()
    checkCanvasElement()
  });

  updatePageResponsiveness();

});

